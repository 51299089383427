<script>
  import { mapState, mapActions } from "vuex";

  export default {
    name: 'ProductModelGallery',
    data() {
      return {
        currentModelIndex: 0,
      }
    },
    props: {
      gallery: [],
      fullGallery: Boolean,
    },
    computed: {
      ...mapState(["currentProductModel", "baseURL", "maps"]),
      truncateDesc() {
				return (item) => {
					if (item?.short_desc && item.short_desc.length >= 28) {
						return item.short_desc.substring(0, 28) + "...";
					} else if (item?.short_desc && item.short_desc.length <= 28) {
						return item.short_desc.substring(0, 28);
					}
					// RETURNING &NBSP; FOR STYLING PURPOSES.
					return "&nbsp;";
				};
			},
    },
    methods:{
      changeGalleryImage(i) {
        this.$emit('changeGalleryImage', i);
      },
    }
  }
</script>
<template>
			<v-card class="text-center">
				<v-card-text class="gallerypics pt-5">
					<div
						class="pic mx-6 mb-3"
						v-for="(item, i) in gallery"
						:key="i"
						@click="changeGalleryImage(i)"
					>
						<v-tooltip
							:color="img.short_desc ? 'primary' : 'transparent'"
							v-for="img in [item]"
							:key="img.path"
							top
							open-delay="500"
						>
							<template v-slot:activator="{ on, attrs }">
								<div v-on="on">
									<img
										v-if="img.image"
										:src="getMedia(img.path)"
										alt=""
									/>
									<img
										v-else-if="item.video"
										src="../../public/img/icons/video-icon.png"
										alt=""
									/>
									<div class="d-flex pic-desc" >
										<div
											class="pic-text"
											v-html="truncateDesc(item)"
										></div>
										<v-icon
											small
											class="pic-icon"
											>mdi-arrow-top-right</v-icon
										>
									</div>
								</div>
							</template>

							<span
								class="tooltip">{{ img.short_desc }}
							</span>
						</v-tooltip>


					</div>
				</v-card-text>
				<v-card-title
					class="pl-0 pt-1 mx-4 gallery-title"
					primary-title
				>
					{{ currentProductModel.name }}
					<v-icon
						@click="$emit('toggleFullGallery')"
						class="galleryclose"
						>mdi-close</v-icon
					>
				</v-card-title>
			</v-card>
</template>

<style lang="scss" scoped>
	@import "@/assets/sass/theme.scss";

	.gallerypics {
		text-align: left;
	}

	@media only screen and (max-width: 1264px) {
		// .gallery {
		//   height: 70%;
		// }
		// .details {
		//   height: 60%;
		// }
		.mainbtns {
			margin-bottom: 80px;
		}

		.gallerypics {
			text-align: center;
		}
	}

	.indicator {
		font-weight: bold;
		color: $white;
	}

	h1 {
		border-top: solid 2px $black;
		font-size: 28px;
		margin-bottom: 3vh;
		font-weight: normal;
	}

	.drawer h1 {
		border-top: solid 1px $black;
		font-size: 16px;
		font-weight: bold;

		text-align: left;
	}

	.gallery {
		.viewAll {
			.v-icon {
				font-size: 1.2em;
			}
		}
	}

	.galleryclose {
		vertical-align: -4px;
		position: absolute;
		right: 12px;
	}

	.gallery-title {
		border-top: solid 2px $black;
		font-weight: normal !important;
		font-size: 16px;
	}

	.pic {
		display: inline-block;
		cursor: pointer;
		user-select: none;
	}

	.pic img {
		max-width: 120px;
		width: 120px;
		max-height: 100px;
		height: auto;
	}

	.pic-desc {
		align-items: flex-start;
		border-top: solid 1px;
		border-color: $black;
		justify-content: space-between;
		width: 100%;

		div.pic-text {
			height: auto;
			line-height: 1.1;
			min-height: 32px;
			margin-top: 4px;
			width: 104px;
		}

		i.v-icon {
			font-size: 16px;
			margin-top: 4px;
		}
	}

	.v-tooltip__content {
		height: auto;
		font-size: 1em;
		line-height: 1.2;
		max-width: 208px;
		
		::v-deep span {
		}
	}

	.gallerypics {
		.pic {
			vertical-align: bottom;
		}

		.videoIcon {
			width: 120px;
			display: flex;
			justify-content: center;
			align-items: center;

			.v-icon {
				padding: 20px 4px;
				font-size: 40px;
			}
		}
	}
</style>
