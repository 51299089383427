<script>
	import { mapState, mapActions } from "vuex";
	import ArrowButton from "@/components/ArrowButton.vue";
	import GetQuote from "@/components/forms/GetQuote.vue";
	import ProductModelGallery from "../components/ProductModelGallery.vue";
	import gsap from "gsap";

	export default {
		name: "ProductModel",
		data() {
			return {
				bgGradient: "#888",
				currentModelIndex: 0,
				currentDoc: {},
				drawer: false,
				documents: [],
				docDialog: false,
				fullGallery: false,
				gallery: [],
				carouselHeight: 400,
				loaded: false,
				textColor: "#fff",
			};
		},
		props: ["id"],
		components: {
			ArrowButton,
			GetQuote,
			ProductModelGallery,
		},
		watch: {
			$route() {
				this.buildProduct();
			},
		},
		created() {
			this.buildProduct();
		},
		mounted() {
			this.$nextTick(() => {
				if (this.$refs.gallery) {
					this.windowResize();
				}
				window.addEventListener("resize", this.windowResize);
			});

			// Restart at Homepage function after no use.
			const vm = this;
			if (process.env.VUE_APP_CLIENT == "kiosk") {
				// Create 5 min timer.
				let timer;
				const startTimer = function () {
					timer = window.setTimeout(function () {
						vm.goHome();
					}, 5 * (60 * 1000));
				};
				startTimer();
				// Add Window 'key' and 'mouse' event listeners.
				Object.keys(window).forEach((key) => {
					if (/^on(key|mouse)/.test(key)) {
						window.addEventListener(key.slice(2), (event) => {
							if (event) {
								clearTimeout(timer);
								startTimer();
							}
						});
					}
				});
			}
		},
		updated() {
			this.$nextTick(function () {
				this.windowResize();
			});
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.windowResize);
		},
		computed: {
			...mapState(["currentProductModel", "baseURL", "maps"]),
			truncateDesc() {
				return (item) => {
					if (item.short_desc) {
						return item.short_desc.substring(0, 14) + "...";
					}
					// RETURNING &NBSP; FOR STYLING PURPOSES.
					return "&nbsp;";
				};
			},
			currentDesc() {
				if (this.currentProductModel) {
					if (this.currentProductModel.static_description) {
						return this.currentProductModel.description;
					} else {
						if (this.currentProductModel.gallery) {
							return this.currentProductModel.gallery[
								this.currentModelIndex
							].value.item_description;
						} else {
							return "";
						}
					}
				} else {
					return "";
				}
			},
			isCMS() {
				return process.env.VUE_APP_CLIENT == "cms";
			},
		},
		methods: {
			...mapActions(["SET_BREADCRUMBS"]),
			async buildProduct() {
				await this.$store.dispatch("SET_PRODUCT_MODEL", this.id);
				this.init();
				// this.setCurrentDesc();
				this.setGallery();
				this.setDocuments();
				this.createBreadcrumb();
				this.bgGradient = this.setGradient(
					this.currentProductModel.color_theme
				);
				this.textColor = this.currentProductModel.color_theme.textcolor;
				this.windowResize();
			},
			init() {
				this.loaded = true;
				var t1 = new gsap.timeline();
				t1.from(".gallery", {
					x: -2000,
					scale: 0.2,
					opacity: 0,
					duration: 0.9,
					ease: "expo.inOut",
				});
				t1.from(
					".details",
					{
						x: 2000,
						duration: 0.9,
						scale: 0.2,
						opacity: 0,
						ease: "expo.inOut",
					},
					"-=.9"
				);
			},
			toggleDoc(doc) {
				this.docDialog = !this.docDialog;
				this.currentDoc = doc;
			},
			setGallery() {
				let galleryArr = [];
				if (
					this.currentProductModel &&
					this.currentProductModel.gallery
				) {
					for (const x of this.currentProductModel.gallery) {
						let galleryObj = {
							name: x.value.item_name,
							video: "",
							image: "",
							path: "",
							mime: "",
							desc: "",
							short_desc: "",
						};
						if (x.value.item_media) {
							galleryObj.video = x.value.item_media.video;
							galleryObj.image = x.value.item_media.image;
							galleryObj.path = x.value.item_media.path;
							galleryObj.mime = x.value.item_media.mime;
						}
						// if (x.value.item_description) {
						galleryObj.short_desc = x.value?.short_item_description;
						galleryObj.desc = x.value?.item_description;
						// }
						galleryArr.push(galleryObj);
					}
				}
				this.gallery = galleryArr;
			},
			setDocuments() {
				if (
					this.currentProductModel &&
					this.currentProductModel.files
				) {
					this.documents = this.currentProductModel.files
						.map((x) => x.value)
						.filter((y) => y.document_file);
				} else {
					this.documents = [];
				}
			},
			createBreadcrumb() {
				//start breadcrumbs ..
				const breadcrumbs = [
					{
						name: this.currentProductModel.name,
						slug: this.id,
						route: "productmodel",
					},
				];
				const recurse = (name) => {
					this.$store.state.maps.forEach((m) => {
						var mapPins = m.map_pins;
						if (m.legend_pins) {
							mapPins = mapPins.concat(m.legend_pins);
						}
						mapPins.forEach((p) => {
							var p_display = (
								((p.value.pin_link || {})[0] || {}).value || {}
							).name;

							if (p_display == name) {
								breadcrumbs.push({
									name: m.name,
									slug: m.name_slug,
									route: "map",
								});
								recurse(m.name);
							}
						});
					});
				};

				const checkgroup = (name) => {
					for (const g of this.$store.state.productGroups) {
						if (g.product_layout[0].value.left_column) {
							var leftcol =
								g.product_layout[0].value.left_column.map(
									(val) => val.value.name
								);
							if (leftcol.indexOf(name) !== -1) {
								return { name: g.name, slug: g.name_slug };
							}
						}
						if (
							g.product_layout[0].value.right_column &&
							g.product_layout[0].value.right_column
								.right_column_products
						) {
							var rightcol =
								g.product_layout[0].value.right_column.right_column_products.map(
									(val) => val.value.name
								);
							if (rightcol.indexOf(name) !== -1) {
								return { name: g.name, slug: g.name_slug };
							}
						}
						if (g.product_layout[0].value.products) {
							var pname = g.product_layout[0].value.products.map(
								(val) => val.value.name
							);
							if (pname.indexOf(name) !== -1) {
								return { name: g.name, slug: g.name_slug };
							}
						}
					}
					return false;
				};

				var prodgroup = checkgroup(this.currentProductModel.name);

				if (prodgroup) {
					breadcrumbs.push({
						name: prodgroup.name,
						slug: prodgroup.slug,
						route: "productgroup",
					});
					recurse(prodgroup.name);
				} else {
					recurse(this.currentProductModel.name);
				}
				breadcrumbs.reverse();
				this.SET_BREADCRUMBS(breadcrumbs);
				//end Breadcrumbs.
			},
			windowResize() {
				if (window.innerWidth > 959) {
					this.carouselHeight = this.$refs.gallery.offsetHeight - 142;
				} else {
					this.carouselHeight = 400;
				}
			},
			toggleDrawer() {
				this.drawer = !this.drawer;
			},
			toggleFullGallery() {
				this.fullGallery = !this.fullGallery;
				this.$store.dispatch("ui/TOGGLE_MODAL", this.fullGallery);
			},
			showModal() {
				this.$store.dispatch("ui/TOGGLE_GET_QUOTE", true);
			},
			changeGalleryImage(i) {
				this.currentModelIndex = i;
				this.toggleFullGallery();
			},
			getDocument(url) {
				window.open(url, "_blank");
			},
			documentIcon(filename) {
				switch (filename.substring(filename.lastIndexOf(".") + 1)) {
					case "pdf":
						return "mdi-file-pdf";
					case "doc":
						return "mdi-file-document";
					case "jpg":
					case "jpeg":
					case "png":
					case "tif":
					case "gif":
						return "mdi-file-image";
					default:
						return "mdi-file-document";
				}
			},
			pauseVideo() {
				// Pauses all videos.
				for (let vid in this.$refs.video) {
					this.$refs.video[vid].pause();
				}
			},
			goHome() {
				this.$router.push("/map/home");
			},
		},
		buyOnline() {
			window.open(this.currentProductModel.buyLink, "_blank");
		},
		beforeRouteLeave(to, from, next) {
			var t1 = new gsap.timeline();
			t1.to(".gallery", {
				x: -1000,
				scale: 0.2,
				duration: 0.3,
				ease: "circ.out",
			});
			t1.to(
				".details",
				{
					x: 1000,
					duration: 0.3,
					scale: 0.2,
					ease: "circ.out",
				},
				"-=.9"
			).then(() => {
				next();
			});
		},
	};
</script>

<template>
	<v-row
		ref="gallery"
		v-show="loaded"
		no-gutters
		class="fill-height pa-0 ma-0 gallery-container"
	>
		<v-col
			class="gallery col-12 col-md-7 col-lg-8"
			:style="{ backgroundImage: bgGradient }"
		>
			<GetQuote></GetQuote>
			<v-row
				no-gutters
				class="pt-10"
			>
				<v-col class="px-10 pt-4 text-center">
					<v-carousel
						:height="carouselHeight"
						hide-delimiters
						:show-arrows="gallery.length > 1"
						v-model="currentModelIndex"
						@change="pauseVideo"
					>
						<v-carousel-item
							class="carousel-item"
							v-for="(item, i) in gallery"
							:key="i"
							:class="{ videoSlide: item.video }"
							eager
						>
							<v-img
								v-if="item.image"
								:src="getMedia(item.path)"
								height="100%"
								max-height="100%"
								contain
								position="center center"
							/>

							<video
								:id="'video' + `${i}`"
								ref="video"
								v-if="item.video"
								width="100%"
								height="auto"
								controls
								style="max-height: 100%; background-color: #000"
							>
								<source :src="getMedia(item.path)" />
							</video>
						</v-carousel-item>
					</v-carousel>
				</v-col>
			</v-row>
			<div
				v-if="gallery.length > 1"
				class="indicator mt-4"
				:style="{ color: textColor }"
			>
				{{ currentModelIndex + 1 }}/{{ gallery.length }}
			</div>
			<v-btn
				v-if="gallery.length > 1"
				rounded
				@click="toggleFullGallery"
				class="viewAll mt-2"
				height="40"
			>
				<v-icon class="mr-4">$grid</v-icon> View All Photos and Videos
			</v-btn>
		</v-col>
		<v-col
			class="detailcolumn flex-column d-flex fill-height col-12 col-md-5 col-lg-4 details text-left px-12"
		>
			<v-row class="flex-row align-start">
				<v-col>
					<h1 class="mt-12 pl-3 pt-2">
						{{ currentProductModel.name }}
					</h1>
					<div v-html="currentDesc"></div>
				</v-col>
			</v-row>
			<v-row class="mainbtns flex-row align-content-end">
				<v-col>
					<ArrowButton
						v-if="documents.length > 0"
						@click="toggleDrawer"
						icon="$download"
						text="Download Documents"
					>
					</ArrowButton>
					<ArrowButton
						class="primary--text"
						@click="showModal()"
						icon="$quote"
						text="Get Quote"
						v-if="
							(!currentProductModel.buyLink && isCMS) ||
							(currentProductModel.buyLink === '' && isCMS)
						"
					>
					</ArrowButton>
					<ArrowButton
						@click="buyOnline()"
						icon="mdi-cash-usd-outline"
						text="Buy Online"
						v-if="
							(currentProductModel.buyLink && isCMS) ||
							(currentProductModel.buyLink !== '' && isCMS)
						"
					>
					</ArrowButton>
				</v-col>
			</v-row>
		</v-col>


		<v-dialog
			v-model="fullGallery"
			max-width="70%"
			width="auto"
			@click:outside="toggleFullGallery"
		>
			<ProductModelGallery
				:gallery="gallery"
				@changeGalleryImage="changeGalleryImage"
				@toggleFullGallery="toggleFullGallery"
			></ProductModelGallery>
		</v-dialog>


		<v-navigation-drawer
			class="drawer pa-0 ma-0"
			v-model="drawer"
			fixed
			temporary
			right
			width="400"
		>
			<v-list-item>
				<h1 class="pt-2 mt-8 mb-2">
					Download {{ currentProductModel.name }} documents
					<v-icon
						@click="toggleDrawer"
						class="close"
						>mdi-close</v-icon
					>
				</h1>
			</v-list-item>
			<v-list-item
				class="document text-button text-left"
				:key="i"
				v-for="(document, i) in documents"
			>
				<a @click.stop="toggleDoc(document)">
					<v-icon class="document-icon mr-0">{{
						documentIcon(document.document_file)
					}}</v-icon>
					{{ document.document_name }}
				</a>
			</v-list-item>
			<v-dialog
				v-model="docDialog"
				fullscreen
				hide-overlay
				transition="dialog-bottom-transition"
				:retain-focus="false"
				v-for="document in documents"
				:key="document.document_name"
			>
				<v-card
					class="text-center"
					height="100%"
					width="100%"
				>
					<v-toolbar
						dark
						color="primary"
					>
						<v-toolbar-title>{{
							currentDoc.document_name
						}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn
							icon
							dark
							@click.stop="toggleDoc(currentDoc)"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar>
					<div class="iframeContainer px-16 pt-8">
						<iframe
							height="90%"
							:key="currentDoc.document_name"
							width="85%"
							:src="
								currentDoc.document_file ? getMedia(currentDoc.document_file) + '#view=fitH' : ''"
							:title="currentDoc.document_file"
						>
						</iframe>
					</div>
				</v-card>
			</v-dialog>
		</v-navigation-drawer>
	</v-row>
</template>

<style lang="scss" scoped>
	.v-main {
		overflow: scroll;

		@media (min-width: 960px) {
			overflow: hidden;
		}

		@media (max-width: 960px) {
			.gallery-container {
				overflow: auto;
			}
		}
	}

	.mainbtns {
		.button {
			padding-top: 0.4em;
			border-top-width: 1px;
			font-size: 16px;
			font-weight: 400;
		}

		.customicon {
			font-size: 1.2em;
		}

		.buttontext {
			margin-left: 1em;
			padding-right: 0.75em;
		}

		.arrowicon {
			margin-left: auto;
		}
	}

	.videoSlide {
		position: relative;
	}

	video {
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}
</style>

<style lang="scss" scoped>
	@import "@/assets/sass/theme.scss";

	.detailcolumn {
		background-color: white;
		height: auto;

		> .row {
			flex: 0 0 auto;
		}

		@media (min-width: 960px) {
			overflow: auto;
			height: 100%;
		}
	}

	@media only screen and (max-width: 1264px) {
		// .gallery {
		//   height: 70%;
		// }
		// .details {
		//   height: 60%;
		// }
		.mainbtns {
			margin-bottom: 80px;
		}
	}

	.indicator {
		font-weight: bold;
		color: $white;
	}

	h1 {
		border-top: solid 2px $black;
		font-size: 28px;
		margin-bottom: 3vh;
		font-weight: normal;
	}

	.drawer h1 {
		border-top: solid 1px $black;
		font-size: 16px;
		font-weight: bold;

		text-align: left;
	}

	.document {
		font-size: 16px;
	}

	.document a {
		text-decoration: none;
		color: $black;
		display: block;
		position: relative;
		padding-left: 28px;
		line-height: 1.4;

		.v-icon {
			position: absolute;
			top: -4px;
			left: 0;
		}
	}

	.document-icon {
		vertical-align: -2px;
	}

	.close {
		top: 40px;
		right: 6px;
		margin-left: 5px;
		position: absolute;
	}

	.gallery {
		.viewAll {
			.v-icon {
				font-size: 1.2em;
			}
		}
	}

	.galleryclose {
		vertical-align: -4px;
		position: absolute;
		right: 12px;
	}

	.gallery-title {
		border-top: solid 2px $black;
		font-weight: normal !important;
		font-size: 16px;
	}

	.pic {
		display: inline-block;
		cursor: pointer;
		user-select: none;
	}

	.pic img {
		max-width: 120px;
		width: 120px;
		max-height: 100px;
		height: auto;
	}

	.pic-desc {
		border-top: solid 1px;
		border-color: $black;
		justify-content: space-between;
		width: 100%;

		::v-deep .pic-text * {
			margin-bottom: 0 !important;
		}
	}

	@media (max-width: 959px) {
		.viewAll {
			margin-bottom: 4em;
		}
	}

	.v-carousel__item {
		// display: flex;
		align-items: center;
	}

	::v-deep .v-dialog--fullscreen {
		overflow-y: hidden;
	}

	.iframeContainer {
		width: 100%;
		height: 100%;
		background-color: var(--v-primary-darken2);
	}
</style>
